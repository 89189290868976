.main-nav {
  color: #404040;
  width: 100%;
  position: absolute !important;
  padding-left: 20px;
}
.main-nav {
  color: #404040;
}
.main-nav-links {
  font-size: 18pt;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  min-height: 10vh;
  background-color: #399437;
  bottom: 0px;
  right: 0px;
}
.footer div {
  margin: 0px 10px;
}
