.page-container {
  min-height: 90vh;
  background-color: #ffffff;
}
.page-content {
  margin: 0 auto;
  width: 80%;
}
.page-content-header {
  margin:0 auto;
  width:80%;
  margin-bottom: 30px;
}
.home-logo {
  width: 100%;
  padding: 90px 0px;
}
.quote-btn {
  margin-top: 40px;
  font-size: 18pt;
  padding: 10px 30px;
}
